<template>
  <div class="mainform">
    <div class="mainHeader">
      创建FA
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form fbxj-table">
      <div class="xunjia-news"><span></span>基本信息</div>
       <div class="qualityBtn" style="marginTop:20px">
          <el-button
            type="primary"
            size="medium"
            plain
            @click="choosequalityBtn"
            >+选择质量通知单</el-button
          >
      </div>
      <el-form class="bascform" ref="form" :model="form" :rules="rules" label-width="120px">
        <div class="content">
          <div class="col col4">
            <el-form-item label="单据标题" required prop="title">
              <el-input v-model="form.title" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商" required prop="supplierName">
              <el-input
                v-model="form.supplierName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称" required prop="companyName">
              <el-input
                placeholder="请输入"
                v-model="form.companyName"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="工厂" required prop="plantName">
              <el-input
                v-model="form.plantName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料编码" required prop="materielCode">
              <el-input
                v-model="form.materielCode"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="PCA要求时间" required prop="pcaTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="form.pcaTime"
                @change="timeForm"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料描述">
              <el-input
                v-model="form.materielName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="ICA要求时间" required prop="icaTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="form.icaTime"
                @change="ictimeForm"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="备注">
              <el-input
                type="textarea"
                v-model="form.bz"
                placeholder="请填写备注"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="xunjia-news"><span></span>问题描述</div>
        <div class="fkpz content">
          <div class="col col4">
            <el-form-item label="问题来源" required prop="problemType">
              <el-select
                v-model="form.problemType"
                filterable
                placeholder="请选择"
                style="width: 70%"
              >
                <el-option label="实物问题" value="1"></el-option>
                <el-option label="交付问题" value="2"></el-option>
                <el-option label="产能问题" value="3"></el-option>
                <el-option label="包装问题" value="4"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
              <!-- <el-select v-model="form.problemType" filterable placeholder="请选择" style="width:30%">
                <el-option label="天津" value="1"></el-option>
                <el-option label="昆山" value="2"></el-option>
                <el-option label="盘锦" value="3"></el-option>
                <el-option label="太原" value="4"></el-option>
                <el-option label="安阳" value="5"></el-option>
                <el-option label="福州" value="6"></el-option>
                <el-option label="青岛" value="7"></el-option>
                <el-option label="乌鲁木齐" value="8"></el-option>
              </el-select> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="重视度" required prop="levelImportance">
              <el-select
                v-model="form.levelImportance"
                filterable
                placeholder="请选择"
              >
                <el-option label="极高" value="1"></el-option>
                <el-option label="高" value="2"></el-option>
                <el-option label="一般" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="紧急度" required prop="emergencyDegree">
              <el-select
                v-model="form.emergencyDegree"
                filterable
                placeholder="请选择"
              >
                <el-option label="非常紧急" value="1"></el-option>
                <el-option label="紧急" value="2"></el-option>
                <el-option label="一般" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="样本数量" required prop="sampleSize">
              <el-input
                v-model="form.sampleSize"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="附件">
              <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :file-list="form.fileList"
              >
                <el-button size="small" type="primary" plain
                  >上传附件</el-button
                >
              </el-upload>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="问题详述" required prop="detailedProblem">
              <el-input
                type="textarea"
                v-model="form.detailedProblem"
                placeholder="请填写内容"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="action">
        <el-button
          class="submit"
          type="primary"
          size="medium"
          @click="preservation"
          >提交</el-button
        >
      </div>
    <dialog-box
      ref="dialogbox"
      dialogWidth="60%"
      :dialogShow="dialogShow"
      @handleClose="handleClose"
      title="选择质量通知单"
      componentName="choosequalityDialog"
      @affirm="affirm"
      :buttonData="[
        { label: '关闭', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'affirm', size: 'mini' },
      ]"
    >
    </dialog-box>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { baseURL } from '@/assets/js/http.js'
import { request, exportForm } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import { MIME } from '@/assets/js/mime.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'AddExceptionFA',
  props: [],
  data: function () {
    return {
      rules: {
        title: [{ required: true, message: '请输入单据标题', trigger: 'blur' }],
        supplierName: [{ required: true, message: '请输入供应商名', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        plantName: [{ required: true, message: '请输入工厂名字', trigger: 'blur' }],
        materielCode: [{ required: true, message: '请输入物料编码', trigger: 'blur' }],
        pcaTime: [{ required: true, message: '请选择pca要求时间', trigger: 'blur' }],
        icaTime: [{ required: true, message: '请选择ica要求时间', trigger: 'blur' }],
        problemType: [{ required: true, message: '请选择问题来源', trigger: 'blur' }],
        levelImportance: [{ required: true, message: '请选择重视度', trigger: 'blur' }],
        emergencyDegree: [{ required: true, message: '请选择紧急度', trigger: 'blur' }],
        sampleSize: [{ required: true, message: '请输入样本数量', trigger: 'blur' }],
        detailedProblem: [{ required: true, message: '请输入问题详情', trigger: 'blur' }]
      },
      form: {
        // title: "",
        // supplierName: "",
        // companyName: "",
        // plantName: "",
        // materielCode: "",
        // pcaTime: "",
        // icaTime: "",
        // problemSource: "",
        // levelImportance: "",
        // emergencyDegree: "",
        // sampleSize: "",
        // detailedProblem: "",
        fileList: []
      },
      ProblemForm: {},
      dialogShow: false,
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: {
        token: localStorage.getItem('token')
      }
    }
  },
  mounted () {},
  created () {},
  methods: {
    choosequalityBtn () {
      // 选择质量通知单按钮
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    affirm () {
      // 选择质量通知单确认按钮
      var newListData =
        this.$refs.dialogbox.$refs.content.$refs.table.selectData
      if (newListData.length > 0) {
        this.newList =
          this.$refs.dialogbox.$refs.content.$refs.table.selectData
        for (const item of this.newList) {
          console.log('hhh', item)
          this.form.qualityCode = item.qualityCode
          this.form.supplierName = item.supplierName
          this.form.supplierCode = item.supplierCode
          this.form.materielName = item.materielName
          this.form.materielCode = item.materielCode
          this.form.plantName = item.plantName
          this.form.companyName = item.companyName
          this.form.companyCode = item.companyCode
          this.form.plantCode = item.plantCode
        }
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的内容',
          type: 'warning'
        })
      }
    },
    // 保存
    preservation () {
      var obj = {
        ...this.form,
        type: 2 // FA
      }
      // console.log("obj", obj);
      // this.$refs.addDialog.$refs.content.$refs.addfilelidt.validate
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          request('/api/quality/8dfa/save', 'post', obj).then((response) => {
            if (response.code === '200') {
              this.$message({
                message: '成功',
                type: 'success'
              })
              this.$router.push('/QualitySynergy/ExceptionManage')
            }
          })
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    timeForm () {
      if (this.form.pcaTime) {
        this.form.pcaTime = dayjs(this.form.pcaTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else {
        this.form.pcaTime = ''
      }
    },
    ictimeForm () {
      if (this.form.icaTime) {
        this.form.icaTime = dayjs(this.form.icaTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else {
        this.form.icaTime = ''
      }
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name
            .substring(file.name.lastIndexOf('.') + 1)
            .toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        }
      )
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.fileList.length; i++) {
        if (this.form.fileList[i].id === file.id) {
          this.form.fileList.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach((element) => {
          this.form.fileList.push(element)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/elform.scss";
.fbxj-table {
  .xunjia-news {
    color: #666;
    font-size: 16px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    span {
      display: inline-block;
      width: 3px;
      background: #ccc;
      vertical-align: -4px;
      height: 20px;
      background: #4487fa;
      margin-right: 5px;
    }
  }
  .action {
    margin: 10px 0 30px;
  }
  .fkpz {
    padding: 20px 0 0;
  }
}
</style>
